import React, { useEffect, useState } from "react";
import { GlobalStyle } from "../assets/styles/globalStyles";
import {
	StyledSection,
	StyledSectionInner,
} from "../assets/styles/commonStyles";
import colors from "../assets/styles/colors";
import SelectModel from "../components/common/SelectModel";
import CarsModelSlider from "../components/CarsModelSlider";
import { connect } from "react-redux";
import {
	getCarlines,
	setCarline,
	setSalesgroup,
} from "../redux/carlines/actions";
import ComponentLoading from "../components/common/ComponentLoading";
import Layout from "../components/common/Layout";
import API from "../classes/api";
import { dataLayerPush } from "../classes/gtm";
import ModelosDetalleGrid from "../components/common/ModelosDetalleGrid";
import l from "lodash";

const RentingEmpresaPage = ({
	location,
	carlines,
	carline,
	salesgroup,
	getCarlines,
	setCarline,
	setSalesgroup,
}) => {
	const [ofertas, setOfertas] = useState(null);
	const [cols, setCols] = useState(null);
	const [pageMode, setPageMode] = useState(null); // carousel, ofertas

	// empezar determinando en que modo vamos a funcionar

	useEffect(() => {
		const pathParts = location.pathname.split("/");
		const index = pathParts.indexOf("renting-empresa");
		if (l.isEmpty(pathParts[index + 1])) {
			setPageMode("carousel");
		} else {
			setPageMode("ofertas");
		}
	}, [location]);

	// pedir carlines a la api

	useEffect(() => {
		if (!carlines || carlines.length === 0) {
			console.log(`re: getting carlines`);
			getCarlines({
				isEmpresa: true,
				ofertaTipo: "renting",
			});
		}
	}, []);

	// si pageMode === ofertas, leer carline y salesgroup de la url y buscarlos en los carlines ya cargados
	// luego buscar ofertas del salesgroup y sus trimlevels

	useEffect(() => {
		if (pageMode === "ofertas" && !l.isEmpty(carlines)) {
			const pathParts = location.pathname.split("/");
			const index = pathParts.indexOf("renting-empresa");
			const carlineCodigo = pathParts[index + 1];
			const salesgroupCodigo = pathParts[index + 2];
			const carline = API.findCarlineFromCarlines(carlines, carlineCodigo);
			if (carline) {
				const salesgroup = API.findSalesgroupFromCarline(
					carline,
					salesgroupCodigo,
				);
				if (salesgroup) {
					setCarline(carline);
					setSalesgroup(salesgroup);
					setOfertas(API.findOfertasByTipo(salesgroup, "renting"));
				}
			}
		}
	}, [pageMode, carlines]);

	// cuando pageMode === ofertas y hay ofertas, determinar cuantas columnas hay que mostrar

	useEffect(() => {
		if (pageMode === "ofertas" && ofertas) {
			if (ofertas.length === 1) {
				setCols("1");
			} else if (ofertas.length === 2) {
				setCols("2");
			} else {
				setCols("3");
			}
		}
	}, [pageMode, ofertas]);

	// el usuario elige un carline

	const handleCarlineChange = (data) => {
		setCarline(data.carline);
		setSalesgroup(data.salesgroup);
	};

	// el usuario elige un salesgroup

	const handleSalesgroupChange = (carline, salesgroup) => {
		window.open(API.buildURL(carline, salesgroup, "renting-empresa"), "_top");
	};

	// gtm

	useEffect(() => {
		if (pageMode === "carousel") {
			if (carline && salesgroup) {
				dataLayerPush({
					seccion: "promociones",
					pagina: "renting-empresa",
					gama: carline.codigo,
					modelo: salesgroup.codigo,
					precio: salesgroup.pvpRecomendado,
					utm_content: "",
					modelo_edicion: "",
				});
			}
		} else if (pageMode === "ofertas") {
			if (ofertas && ofertas.length > 0) {
				dataLayerPush({
					seccion: "promociones",
					pagina: "renting-empresa",
					gama: carline.codigo,
					modelo: salesgroup.codigo,
					precio: salesgroup.pvpRecomendado,
					utm_content: "",
					modelo_edicion: "",
				});
			}
		}
	}, [pageMode, carline, salesgroup, ofertas]);

	return (
		<Layout>
			<main>
				<GlobalStyle />

				{/* Carousel loading */}

				{pageMode === "carousel" && !carlines && <ComponentLoading />}

				{/* Carousel carlines/salesgroups */}

				{pageMode === "carousel" && carlines && (
					<StyledSection>
						<StyledSectionInner noPaddingTopBot={true}>
							<div>
								<SelectModel
									carlines={carlines}
									onChange={handleCarlineChange}
								/>
								{carlines && (
									<CarsModelSlider
										carlines={carlines}
										onSalesgroupChange={handleSalesgroupChange}
										mode="renting-empresa"
									/>
								)}
							</div>
						</StyledSectionInner>
					</StyledSection>
				)}

				{/* Ofertas Loading */}

				{pageMode === "ofertas" && !ofertas && <ComponentLoading />}

				{/* Ofertas */}

				{pageMode === "ofertas" && ofertas && cols && (
					<StyledSection>
						<StyledSectionInner noPaddingTopBot={true}>
							<ModelosDetalleGrid
								cols={cols}
								carline={carline}
								salesgroup={salesgroup}
								ofertas={ofertas.sort((a, b) => {
									return a.oferta.precio - b.oferta.precio;
								})}
								mode="renting-empresa"
							/>
						</StyledSectionInner>
					</StyledSection>
				)}
			</main>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	const { carlines, carline, salesgroup } = state.carlines;
	return {
		carlines,
		carline,
		salesgroup,
	};
};

const mapDispatchToProps = {
	getCarlines,
	setCarline,
	setSalesgroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(RentingEmpresaPage);
